















import { Component, Vue } from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class BasicInfo extends Vue {
  public user_id = "";
  public form_list: MyForm.formList[] = [];

  // form组件的回调（值改变时获取数据）
  formChange(form_list: MyForm.formList[]) {
    this.form_list = form_list;
  }
  // 提交表单
  submit() {
    let form: any = this.$refs.form;
    if (form.ValidateAll()) {
      this.submitRequest(this.$api.getFormData([this.form_list]));
    }
  }
  // 提交表单（请求接口）
  submitRequest(data: Dictionary<string>) {
    let _this = this;
    this.$api.request({
      url: "user/users/add-parent",
      data,
      success(res) {
        _this.$api.goBack();
      }
    });
  }

  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.form_list = [
      {
        name: "parent_name",
        value: "",
        label: "推荐码",
        type: "input",
        placeholder: "请输入推荐码",
        required: true
      }
    ];
    this.$api.refreshForm([this.$refs.form]);
  }
}
